@use '../abstract/mixins'as mixin;
@use "../abstract/responsive"as rwd;

$c-white: #FFFFFF;
$calendar-bg-color: var(--promo-box-slider-bg);
$calendar-item-color: #a8c0ff33;
$transition: all .4s ease;

/* Kalendarż prezydenta wyświetlany na stronie głównej */
.calendarSection {
  margin-top: 20px;
  padding: 40px 0 50px;
  background-color: $calendar-bg-color;

  @include rwd.breakpoint(mobile) {
    padding: 20px 0;
  }

  .topContent {
    display: flex;

    @include rwd.breakpoint(phonesUp) {
      justify-content: space-between;
      align-items: baseline;
    }

    @include rwd.breakpoint(phonesDown) {
      flex-direction: column;
    }
  }

  .sectionTitle {
    @include rwd.breakpoint(phonesDown) {
      margin-bottom: 0 !important;
      padding-left: 0;
      text-align: center;

      &::before {
        display: none;
      }
    }
  }

  .dataBoxCalendar {
    @include mixin.font(18px, 26px, 700, $c-white);
    display: flex;
    align-items: center;

    @include rwd.breakpoint(largePhones) {
      font-size: 16px;
    }

    @include rwd.breakpoint(phonesDown) {
      margin-bottom: 16px;
      margin-top: 24px;
      justify-content: center;
    }

    time {
      margin: 0 12px;

      @include rwd.breakpoint(largePhones) {
        margin: 0 4px;
      }
    }

    time>.timeIcon {
      margin-right: 5px;
    }
  }

  .sectionTitle {
    color: $c-white;

    @include rwd.breakpoint(phonesDown) {
      margin-bottom: 7px;
    }
  }

  .calendarSlider {
    color: $c-white;

    @include rwd.breakpoint(mobileUp) {
      margin-top: 20px;
    }

    .item {

      time {
        font-weight: 700;
      }

      .title {
        font-weight: 700;
        margin: 0;
      }
    }

    .slideNumber {
      color: $c-white;
      display: none !important;

    }

    .splide__arrow {
      top: 35%;

      &::before {
        color: $calendar-item-color;
      }

      &:hover,
      &:focus {
        &::before {
          color: var(--main-color);
          outline: none;
        }
      }
    }

    &__oneDay {
      display: flex;
      justify-content: flex-start;

      @include rwd.breakpoint(mobile) {
        flex-direction: column;
      }
    }

    &__oneDayItem {
      box-sizing: border-box;
      border: solid 1px $c-white;
      padding: 20px;
      @include mixin.font(18px, 26px, 700);
      background-color: $calendar-item-color;

      @include rwd.breakpoint(mobileUp) {
        width: calc(25% - 28px);
      }

      @include rwd.breakpoint(phonesUp) {
        margin: 14px;
      }

      @include rwd.breakpoint(phonesDown) {
        margin: 10px;
      }
    }

    &__arrow {
      box-sizing: border-box;
      border-radius: 2px;
      border: solid 1px $c-white;
      padding: 2px;
      width: 30px;
      height: 30px;
      font-size: 12px;
      color: $c-white;
      background-color: $calendar-item-color;
      transition: $transition;
      cursor: pointer;

      &::before {
        font-weight: 700;
      }

      &:hover {
        transition: $transition;
        border-color: var(--main-color);
        border-width: 3px;

        &::before {
          color: var(--main-color);
        }
      }
    }

    &__prev {}

    &__next {}
  }

}

// The forced-colors media feature indicates whether or not the browser is currently in forced-colors mode.
// Lewy Alt + lewt Shift + Print Screen - włącza i wyłącza motyw wysokiego kontrastu

@media (forced-colors: active) {
  $forcedBorder: 1px solid transparent;

  @mixin customBorder($size: 1px) {
    $forcedBorder: $size solid transparent;
    border: $forcedBorder;
  }

  .forcedColors {
    .calendarSection {
      border-top: solid 1px transparent;
      border-bottom: solid 1px transparent;

      .calendarSlider__arrow {

        &:hover,
        &:focus,
        &:active {
          outline: transparent solid 4px;
          outline-offset: 2px;
        }
      }

      .dataBoxCalendar time #sliderTopDate {
        border-bottom: solid 1px transparent;
      }
    }
  }
}